import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slide"]
  connect() {
    this.previousIndex = 0;
    this.index = 0;
    this.interval;
    this.redirected = false;
    this.startInterval();
    this.updateDots();

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.restartInterval();
        this.visible = true;
      } else {
        this.visible = false;
      }
    });
    this.visible = true;
    this.update('impression');

    const eventListener = () => {
      if (!this.redirected) this.restartInterval();
      this.redirected = false;
    };

    document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = document.onclick = document.onscroll = eventListener;

  }

  startInterval() {
    this.interval = setInterval(() => {
      this.next();
    }, 5000);
  }

  resetInterval() {
    clearInterval(this.interval);
    this.startInterval();
  }

  restartInterval() {
    if (this.interval === undefined) {
      this.startInterval();
    }
  }

  previous() {
    if (this.index > 0) {
      this.slideTargets[this.index].classList.add("hidden")
      this.index -= 1
      this.slideTargets[this.index].classList.remove("hidden")
    } else {
      this.slideTargets[this.index].classList.add("hidden")
      this.index = this.slideTargets.length - 1
      this.slideTargets[this.index].classList.remove("hidden")
    }
    this.resetInterval();
    this.updateDots();
  }

  next() {
    if (this.index < this.slideTargets.length - 1) {
      this.slideTargets[this.index].classList.add("hidden")
      this.index += 1
      this.slideTargets[this.index].classList.remove("hidden")
    } else {
      this.slideTargets[this.index].classList.add("hidden")
      this.index = 0
      this.slideTargets[this.index].classList.remove("hidden")
    }
    this.resetInterval();
    this.updateDots();
  }

  goToSlide(event) {
    const newIndex = event.target.dataset.carouselIndex;
    if (newIndex !== this.index) {
      this.slideTargets[this.index].classList.add("hidden");
      this.index = parseInt(newIndex);
      this.slideTargets[this.index].classList.remove("hidden");
      this.resetInterval();
      this.updateDots();
    }
  }

  updateDots() {
    if (this.previousIndex !== this.index) this.update('impression');
    const dots = this.element.querySelectorAll("[data-action='click->carousel#goToSlide']");
    dots.forEach((dot, idx) => {
      dot.classList.toggle("bg-gray-300", this.index !== idx);
      dot.classList.toggle("bg-gray-600", this.index === idx);
    });
    this.previousIndex = this.index
  }
  
  url() {
    clearInterval(this.interval);
    this.interval = undefined;
    this.redirected = true;
    this.update('click');
  }

  update(type) {
    if (!this.visible) return;
    const url = '/banner'

    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    }

    var bannerId = document.querySelector('#banner_id_' + this.index).dataset.bannerId;
    if (bannerId == 0) return;

    const data = {
      id: bannerId,
      type: type
    };

    Rails.ajax({
      type: 'POST',
      url: url,
      headers: headers,
      data: JSON.stringify(data),
      success: (data) => {
      },
      error: (error) => {
        console.error(error)
      }
    })
  }
}