import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    document.addEventListener('change', function(event) {
        const target = event.target;
        if (target.tagName === 'SELECT' && target.classList.contains('form-control')) {
            reorderTableRows();
        }
    });

    function reorderTableRows() {
        const table = document.getElementById('banner-table');
        const rows = Array.from(table.getElementsByClassName('banner-row'));

        rows.sort(function(a, b) {
            const valueA = parseInt(a.querySelector('.form-control').value);
            const valueB = parseInt(b.querySelector('.form-control').value);
    
            if (valueA !== valueB) {
                return valueA - valueB;
            } else {
                const indexA = Array.from(a.parentNode.children).indexOf(a);
                const indexB = Array.from(b.parentNode.children).indexOf(b);
                return indexB - indexA;
            }
        });

        rows.forEach((element, index) => {
            element.querySelector('.form-control').value = index + 1;
        })
      
        for (let i = 0; i < rows.length; i++) {
          table.appendChild(rows[i]);
        }
    }

  }
 
}



