import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    if (this._isTurboNativeApp) {
        window.TurboNativeBridge.postMessage("session");
    }
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf("Turbo Native") !== -1
  }
}