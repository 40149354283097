import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
export default class extends Controller {
    
    static targets = ['helpTab','notificationsTab','notificationTabContent','helpTabContent','title','uuid',"trashIcon","trash"]

    static values = {
        selectedTab : {
            type : String,
            default : "Notifications"
        }
    }

    showHelpTab(){
        this.titleTarget.textContent = this.selectedTabValue
        this.helpTabTarget.style.backgroundColor = "#FFA523"
        this.notificationsTabTarget.style.backgroundColor = "#fcf4e8"
        this.helpTabTarget.style.color = "#fcf4e8"
        this.notificationsTabTarget.style.color = "#FFA523"
        this.helpTabContentTarget.style.display = "block"
        this.notificationTabContentTarget.style.display = "none"
        this.selectedTabValue = "Get help"
    }
    showNotificationTab(){
        this.helpTabTarget.style.backgroundColor = "#fcf4e8"
        this.notificationsTabTarget.style.backgroundColor = "#FFA523"
        this.helpTabTarget.style.color = "#FFA523"
        this.notificationsTabTarget.style.color = "#fcf4e8"
        this.helpTabContentTarget.style.display = "none"
        this.notificationTabContentTarget.style.display = "block"
        this.selectedTabValue = "Notifications"
    }

    selectNotification(event){
        var uuid = JSON.parse(this.uuidTarget.value)
        event.target.checked ? uuid.push(event.params.uuid) : uuid.splice(uuid.indexOf(event.target.uuid), 1)
        this.uuidTarget.value = JSON.stringify(uuid)
        if(this.uuidTarget.value == "[]"){
            this.trashIconTarget.disabled = true
            this.trashTarget.style = "opacity: 0.5"
        }else{
            this.trashIconTarget.disabled = false
            this.trashTarget.style = "opacity: 1.0"
        }
    }

    selectedTabValueChanged(value,previousValue){
        this.titleTarget.textContent = value
        this.trashIconTarget.style.display = value == "Get help" ? "none" : "block"
    }

    initialize(){
        document.querySelectorAll(".pagination .disabled").forEach(element => element.style.color = '#FFA523')
        document.querySelectorAll(".pagination .page a").forEach(element => element.style.color = '#FFA523')
        document.querySelector(".active").style.color = "white"
        document.querySelector(".active").style.borderColor = "#FFA523"
        document.querySelector(".active").style.backgroundColor = "#FFA523"
    }
}