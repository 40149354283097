import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {

    connect() {
       
        //date range picker intialization
        $('.daterange').daterangepicker({
            opens: 'left',
        })
          
        var elements = document.getElementsByTagName('*');

        for (var i = 0; i < elements.length; i++) {
            var element = elements[i];
            if (element.id.indexOf('filter-type') === 0) {
                var section = document.getElementById(element.id.split('_')[1] + "-filter-input");
                if (element.checked) section.style.display = "block";
                addEventListenerById(element.id);
            }
        }

        //hide or show filter input fields based on checked value
        function addEventListenerById(id) {
            document.getElementById(id).addEventListener("change", function() {
                var section = document.getElementById(id.split('_')[1] + "-filter-input");
                if (this.checked) {
                    section.style.display = "block";
                } else {
                    section.style.display = "none";
                }
            });
        }

        document.querySelectorAll('.report-type-select').forEach(select => {
            var employee_filter = document.getElementById("employee-filters");
            var company_filter = document.getElementById("company-filters");
            var invoice_filter = document.getElementById("invoice-filters");
            var advance_filter = document.getElementById("advance-filters");
            var banner_filter = document.getElementById("banner-filters");

            select.addEventListener('change', () => {
                const action = select.options[select.selectedIndex].value;
                employee_filter.style.display = "none";
                company_filter.style.display = "none";
                invoice_filter.style.display = "none";
                advance_filter.style.display = "none";
                banner_filter.style.display = "none";

                switch (action){
                    case 'employee_report':
                        employee_filter.style.display = "block";
                        break;
                    case 'company_report':
                        company_filter.style.display = "block";
                        break;
                    case 'invoice_report':
                        invoice_filter.style.display = "block";
                        break;
                    case 'advance_report':
                        advance_filter.style.display = "block";
                        break;
                    case 'banner_report':
                        banner_filter.style.display = "block";
                        break;
                }

            });
        });

        function resetFilters(){
            //trigger reset button

        }
  
    }
   
  }