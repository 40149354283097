import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "inputField", "submitButton", "requestedAmount", "receiveAmount" ]
  static values = {
    max: Number
  }

  connect() {
    // this.amount(event);
    // console.log("Hello, Stimulus!", this.element)
  }


  setamount({ params: { amount } }) {
    this.inputFieldTarget.value = amount
    this.requestedAmountTarget.innerHTML = amount
    this.receiveAmountTarget.innerHTML = amount - 10
  }

  enteramount(event) {
    this.requestedAmountTarget.innerHTML = event.target.value
    this.receiveAmountTarget.innerHTML = event.target.value - 10
  }

}
