import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    //To handle relogin on iOS, return once handled
    const urlParams = new URLSearchParams(window.location.search);
    const reloginChecked = urlParams.get("reloginChecked");
    
    if (reloginChecked != null) return

    if (this._isTurboNativeApp) {
      window.TurboNativeBridge.postMessage("relogin")
    }
  }

  signIn(event) {
    if (this._isTurboNativeApp) {
      event.preventDefault()
      event.stopImmediatePropagation()
      let mobile_number = document.querySelector('#user_mobile_number').value
      let password = document.querySelector('#password').value
      window.TurboNativeBridge.postMessage("signIn", {mobile_number: mobile_number, password: password})
    }
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf("Turbo Native") !== -1
  }
}
