import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = ['hrAdminTab', 'dgAdminTab', 'hrAdminContent', 'dgAdminContent'];

  connect() {
    const selectedTab = localStorage.getItem("selected_tab");
    if (selectedTab === 'dg_admin') {
      this.showDgAdminTab();
    } else {
      this.showHrAdminTab();
    }
  }

  showHrAdminTab() {
    this.hrAdminTabTarget.classList.add('border-b-4');
    this.hrAdminTabTarget.classList.remove('text-gray-600');
    this.dgAdminTabTarget.classList.remove('border-b-4');
    this.dgAdminTabTarget.classList.add('text-gray-600');
    this.hrAdminContentTarget.style.display = 'block';
    this.dgAdminContentTarget.style.display = 'none';
    localStorage.setItem("selected_tab", "hr_admin");
  }

  showDgAdminTab() {
    this.hrAdminTabTarget.classList.remove('border-b-4');
    this.hrAdminTabTarget.classList.add('text-gray-600');
    this.dgAdminTabTarget.classList.add('border-b-4');
    this.dgAdminTabTarget.classList.remove('text-gray-600');
    this.hrAdminContentTarget.style.display = 'none';
    this.dgAdminContentTarget.style.display = 'block';
    localStorage.setItem("selected_tab", "dg_admin");
  }
}