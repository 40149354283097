// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleButton"]

  triggerFileExplore(){
    document.getElementById("account_avatar").click()
  }

  upload(){
    const [file] = event.target.files
      if (file) {
        document.getElementById("avatar-preview").src = URL.createObjectURL(file)
      }
  }

  toggle(event){
    document.getElementById("talenox-enable").value = event.target.checked
  }
}
