import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"];

  switch(event) {
    const accountId = event.target.dataset.accountId;
    const url = '/accounts/' + accountId + '/switch';

    Rails.ajax({
        type: 'GET',
        url: url,
        success: (data) => {
            if (this._isTurboNativeApp) {
                window.TurboNativeBridge.postMessage("switchAccount")
            } else {
                window.location.href = '/profile'
            }
        },
        error: (error) => {
            console.error(error)
        }
    })

  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf("Turbo Native") !== -1
  }
}