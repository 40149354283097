import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
export default class extends Controller {
    
    static targets = ['liveTab','expiredTab','liveTabContent','expiredTabContent', 'title']

    static values = {
        selectedTab : {
            type : String,
            default : "Live & Upcoming"
        }
    }

    
    showExpiredTab(){
        this.titleTarget.textContent = this.selectedTabValue
        this.expiredTabTarget.style.backgroundColor = "#5a5a5a"
        this.liveTabTarget.style.backgroundColor = "#D3D3D3"
        this.expiredTabTarget.style.color = "#FFFFFF"
        this.liveTabTarget.style.color = "#5a5a5a"
        this.expiredTabContentTarget.style.display = "block"
        this.liveTabContentTarget.style.display = "none"
        this.selectedTabValue = "Ended"
    }
    
    showLiveTab(){
        this.expiredTabTarget.style.backgroundColor = "#D3D3D3"
        this.liveTabTarget.style.backgroundColor = "#5a5a5a"
        this.expiredTabTarget.style.color = "#5a5a5a"
        this.liveTabTarget.style.color = "#FFFFFF"
        this.expiredTabContentTarget.style.display = "none"
        this.liveTabContentTarget.style.display = "block"
        this.selectedTabValue = "Live & Upcoming"
    }

    selectedTabValueChanged(value,previousValue){
        this.titleTarget.textContent = value
    }
}