
export function sessionTimeout() {
    const isWeb = navigator.userAgent.indexOf("Turbo Native") == -1
    if(isWeb){
        window.location.href = '/session/session_timeout'
    }else{
        //window.TurboNativeBridge.postMessage("sessionTimeout");
    }
}

function initializeTimeout(){
    const excludePaths =  ["/users/sign_up","/users/sign_in","/users/password/new"]
    const pathname = window.location.pathname
    fetch('/session/get_session?name=mobile_number')
    .then(res => res.json())
    .then(data => {
        //alert(data.sessionValue)
        if (!excludePaths.includes(pathname) && data.sessionValue != null) {
            var timeout = setTimeout(sessionTimeout, 5*(60*1000))
            document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = document.onclick = document.onscroll = function () {
                clearTimeout(timeout);
                timeout = setTimeout(sessionTimeout, 5*(60*1000));
            };
        }
    })
}

initializeTimeout()

