import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = ['hrAdminTab', 'dgAdminTab', 'hrAdminContent', 'dgAdminContent', 'allTimeTab','thisMonthTab','allTimeTabContent','thisMonthTabContent', 'title'];

  static values = {
    selectedTab : {
        type : String,
        default : "All Time"
    }
  }

  connect() {
    const selectedTab = localStorage.getItem("selected_tab");
    if (selectedTab === 'dg_admin') {
      this.showDgAdminTab();
    } else {
      this.showHrAdminTab();
    }
  }

  showHrAdminTab() {
    this.hrAdminTabTarget.classList.add('border-b-4');
    this.hrAdminTabTarget.classList.remove('text-gray-600');
    this.dgAdminTabTarget.classList.remove('border-b-4');
    this.dgAdminTabTarget.classList.add('text-gray-600');
    this.hrAdminContentTarget.style.display = 'block';
    this.dgAdminContentTarget.style.display = 'none';
    localStorage.setItem("selected_tab", "hr_admin");
  }

  showDgAdminTab() {
    this.hrAdminTabTarget.classList.remove('border-b-4');
    this.hrAdminTabTarget.classList.add('text-gray-600');
    this.dgAdminTabTarget.classList.add('border-b-4');
    this.dgAdminTabTarget.classList.remove('text-gray-600');
    this.hrAdminContentTarget.style.display = 'none';
    this.dgAdminContentTarget.style.display = 'block';
    localStorage.setItem("selected_tab", "dg_admin");
  }

  thisMonthTab(){
    this.titleTarget.textContent = this.selectedTabValue
    this.thisMonthTabTarget.style.backgroundColor = "#FFFFFF"
    this.allTimeTabTarget.style.backgroundColor = "#D3D3D3"
    this.thisMonthTabTarget.style.color = "#5a5a5a"
    this.allTimeTabTarget.style.color = "#FFFFFF"
    this.thisMonthTabTarget.style.fontWeight = "bold"
    this.allTimeTabTarget.style.fontWeight = "400"
    this.allTimeTabContentTarget.style.height = "0px"
    this.allTimeTabContentTarget.style.visibility = "hidden"
    this.thisMonthTabContentTarget.style.height = "auto"
    this.thisMonthTabContentTarget.style.visibility = "visible"
    this.selectedTabValue = "This Month"
  }
  
  allTimeTab(){
    this.thisMonthTabTarget.style.backgroundColor = "#D3D3D3"
    this.allTimeTabTarget.style.backgroundColor = "#FFFFFF"
    this.thisMonthTabTarget.style.color = "#FFFFFF"
    this.allTimeTabTarget.style.color = "#5a5a5a"
    this.thisMonthTabTarget.style.fontWeight = "400"
    this.allTimeTabTarget.style.fontWeight = "bold"
    this.allTimeTabContentTarget.style.height = "auto"
    this.allTimeTabContentTarget.style.visibility = "visible"
    this.thisMonthTabContentTarget.style.height = "0px"
    this.thisMonthTabContentTarget.style.visibility = "hidden"
    this.selectedTabValue = "All Time"
  }
  
  selectedTabValueChanged(value,previousValue){
      this.titleTarget.textContent = value
  }
}