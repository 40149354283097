import { Controller } from "@hotwired/stimulus";
import jspreadsheet from "jspreadsheet-ce";

const generateCols = () => {
  //NOTE!!!: When adding/removing columns below, 
  //         make sure the mapping is correctly handled in files that are using these data
  return [
    { type: "hidden", title: "Advance ID" },
    { type: "hidden", title: "Employee ID" },
    { type: "text", title: "Requested by", width: 180, readOnly: true },//2
    { type: "text", title: "Amount", width: 110, readOnly: true },//3
    { type: "text", title: "Admin fee", width: 110, readOnly: true },//8->4
    { type: "text", title: "Discount amount", width: 130, readOnly: true },//13->5
    { type: "calendar", title: "Requested on", width: 170, readOnly: true, options: { format:'DD/MM/YYYY HH24:MI'} },//4->6
    { type: "text", title: "Company", width: 240, readOnly: true },//5->7
    { type: "text", title: "Type", width: 120, readOnly: true },//6->8
    { type: "dropdown", title: "Approve / Reject / Exclude", width: 300, source:['Approve', 'Reject', 'Exclude'] }, //7->9
    { type: "hidden", title: "Tax" }, //9->10
    { type: "hidden", title: "Account id" },//10->11
    { type: "hidden", title: "Employee no" },//11->12
    { type: "hidden", title: "Integration Id" },//12->13
    { type: "hidden", title: "Cost by" },//14
  ]
}

const generateErrorStyles = (errors) => {
  const table = document.getElementsByClassName("jexcel")[0];

  Object.keys(errors).forEach((row) => {
    table.rows[row].classList.add("spreadsheet-error-row")
    errors[row]["cells"].forEach(cell => {
      table.rows[row].cells[cell].classList.add("spreadsheet-error-cell")
    });
  });
}

export default class extends Controller {
  static targets = [
    "payoutsheet",
    "importData",
    "importForm"
  ]

  static values = {
    errors: Object,
    payoutsheetData: Array
  }

  connect() {
    this.initSpreadsheet();
    generateErrorStyles(this.errorsValue);
  }

  initSpreadsheet() {
    this.payoutsheet = jspreadsheet(this.payoutsheetTarget, {
      data: this.payoutsheetDataValue,
      columns: generateCols(),
      // disable the right-click menu
      // contextMenu: () => { return []; }
      tableOverflow: true,
      tableWidth: "1370px",
      tableHeight: "1200px",
      allowInsertRow: false,
      freezeColumns: 1
    });
  }

  importData(e) {
    e.preventDefault();
    // update data in hidden field in form and submit it
    this.importDataTarget.value = JSON.stringify(this.payoutsheet.getJson());
    this.importFormTarget.submit();
  }
}