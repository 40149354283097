import { Controller } from "@hotwired/stimulus";
import jspreadsheet from "jspreadsheet-ce";

const generateCols = () => {
  return [
    { type: "text", title: "Full Name", width: 190 },
    { type: "calendar", title: "Date of Birth", width: 100, options: { readonly:0 } },
    { type: "dropdown", title: "Nationality", width: 120, source:['Malaysia', 'Foreigner'] },
    { type: "text", title: "IC Number", width: 140 },
    { type: "text", title: "Email", width: 160 },
    { type: "numeric", title: "Mobile number", width: 140 },
    { type: "text", title: "Job Title", width: 100 },
    { type: "calendar", title: "Hire date", width: 100, options: { readonly:0 } },
    { type: "numeric", title: "Current salary", width: 120 },
    { type: "dropdown", title: "Bank", width: 120, source: [
      "AFFIN BANK","AFFIN ISLAMIC BANK","AGROBANK - SPI","AGROBANK","AL-RAJHI BANK","ALLIANCE BANK","ALLIANCE ISLAMIC BANK","AMISLAMIC BANK","AMBANK","BANK ISLAM MALAYSIA","BANK KERJASAMA RAKYAT","BANK MUAMALAT","BANK NEGARA MALAYSIA","BANK OF AMERICA","BANK OF CHINA","BANK PERTANIAN - AGROBANK","BANK SIMPANAN NASIONAL","BSN - SPI","BNP PARIBAS","BNP PARIBAS - SPI","BANGKOK BANK","CHINA CONSTRUCTION BANK","CIMB BANK","CIMB ISLAMIC BANK","CITIBANK","CITIBANK SPI","DEUTSCHE BANK","DEUTSCHE BANK - SPI","HONG LEONG BANK","HONG LEONG ISLAMIC BANK","HSBC BANK","HSBC AMANAH","INDUSTRIAL AND COMM BANK OF CHINA","JP MORGAN CHASE BANK","KUWAIT FINANCE HOUSE","MAYBANK","MAYBANK ISLAMIC","MBSB BANK","MIZUHO CORPORATE BANK","MUFG BANK","OCBC AL-AMIN BANK","OCBC BANK","PUBLIC BANK","PUBLIC ISLAMIC BANK","RHB BANK","RHB ISLAMIC BANK","STANDARD CHARTERED BANK","STANDARD CHARTERED SAADIQ","SUMITOMO MITSUI BANKING CORPORATION","UNITED OVERSEAS BANK (UOB)"
    ]},
    { type: "numeric", title: "Account no", width: 130 }
  ]
}

const generateErrorStyles = (errors) => {
  const table = document.getElementsByClassName("jexcel")[0];

  Object.keys(errors).forEach((row) => {
    table.rows[row - 1].classList.add("spreadsheet-error-row")
    errors[row]["cells"].forEach(cell => {
      table.rows[row - 1].cells[cell].classList.add("spreadsheet-error-cell")
    });
  });
}

export default class extends Controller {
  static targets = [
    "spreadsheet",
    "importData",
    "importForm"
  ]

  static values = {
    errors: Object,
    spreadsheetData: Array
  }

  connect() {
    this.initSpreadsheet();
    generateErrorStyles(this.errorsValue);
  }

  initSpreadsheet() {
    this.spreadsheet = jspreadsheet(this.spreadsheetTarget, {
      data: this.spreadsheetDataValue,
      columns: generateCols(),
      // disable the right-click menu
      // contextMenu: () => { return []; }
      tableOverflow: true,
      tableWidth: "1470px",
      tableHeight: "1200px",
      freezeColumns: 1
    });
  }

  importData(e) {
    e.preventDefault();
    // update data in hidden field in form and submit it
    this.importDataTarget.value = JSON.stringify(this.spreadsheet.getJson());
    this.importFormTarget.submit();
  }
}