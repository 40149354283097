import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["adminFee","promotionId","discountAmount","totalFee","totalAmount","othersTextField"]

    static values = { 
        adminFee: {
            type: Number,
            default: 10
        },
        promotionId: {
            type: Number,
            default: 0
        },
        discountAmount: {
            type: Number,
            default: 0
        },
        totalFee: {
            type: Number,
            default: 0  
        },
        salary: {
            type: Number,
            default: 0
        },
        selectedCategories:{
            type: Array,
            default: []
        }
    }
    
    adminFeeValueChanged(value,previousValue){
        if (this.discountAmountValue === 0) {
            document.getElementById("fee").value = value;
            this.totalAmountTarget.textContent = `RM ${this.salaryValue - value}`
        }
    }

    totalFeeValueChanged(value,previousValue){
        document.getElementById("fee").value = value;
        this.totalAmountTarget.textContent = `RM ${this.salaryValue - value}`
    }

    toggle(event) {
        document.getElementById("isUrgentRequest").value = event.target.checked
        this.adminFeeValue = event.target.checked ? 20 : 10
        this.adminFeeTarget.textContent = `RM ${this.adminFeeValue}`

        this.handlePromotion()
        this.totalFeeValueChanged(this.totalFeeValue)
    }

    categoriesButton(event){                                                                                             
        const category = event.params.category
        if(!this.selectedCategoriesValue.includes(category)){
            this.selectedCategoriesValue.push(category)
            document.getElementById(category).classList.remove("bg-white")
            document.getElementById(category).classList.add("selected")
        }else{
            document.getElementById(category).classList.remove("selected")
            document.getElementById(category).classList.add("bg-white")
            this.selectedCategoriesValue.splice(this.selectedCategoriesValue.indexOf(category), 1)
        }
        this.isShowOthersTextField()
        document.getElementById("categories").value = JSON.stringify(this.selectedCategoriesValue)
    }
    isShowOthersTextField(){
        this.othersTextFieldTarget.style.display = this.selectedCategoriesValue.includes("others") ? "block" : "none"
    }
    isSelectOthers(){
        const urlParams = new URLSearchParams(window.location.search);
        const others = urlParams.get('others');
        if(others == "true"){
            document.getElementById("others").style.backgroundColor = "lightgreen"
            if(!this.selectedCategoriesValue.includes("others")) this.selectedCategoriesValue.push("others")
            this.isShowOthersTextField()
            document.getElementById("categories").value = JSON.stringify(["others"])
        }
    }
    handlePromotion(){
        //reset promotion
        this.discountAmountValue = 0;
        document.getElementById("promotionId").value = null;
        document.getElementById("discountAmount").value = this.discountAmountValue;

        var isUrgent = document.getElementById("isUrgentRequest").value;
        var selectedPromotion = null;
        
        if (isUrgent === "true"){
            var urgentPromotion = document.getElementById("urgent_promotion");
            if (urgentPromotion) {
                selectedPromotion = JSON.parse(urgentPromotion.dataset.urgent_promotion);
            }
        } else {
            var regularPromotion = document.getElementById("regular_promotion");
            if (regularPromotion) {
                selectedPromotion = JSON.parse(regularPromotion.dataset.regular_promotion);
            }
        }

        //Return if no applicable promotion
        if (!selectedPromotion) {
            document.getElementById("feeDiscount").classList.add("hidden");
            document.getElementById("adminFee").style.textDecoration = "none";
            document.getElementById("totalFee").classList.add("hidden");
            return
        }

        //Display discount/promotion
        document.getElementById("feeDiscount").classList.remove("hidden");
        document.getElementById("adminFee").style.textDecoration = "line-through";
        document.getElementById("totalFee").classList.remove("hidden");

        //Apply promotion based on discount type
        if (selectedPromotion["discount_type"] == "RM") {
            this.discountAmountValue = selectedPromotion["discount_value"];
        } else {
            this.discountAmountValue = selectedPromotion["discount_value"] * (this.adminFeeValue/100);
        }
        document.getElementById("promotionId").value = selectedPromotion["id"];
        document.getElementById("discountAmount").value = this.discountAmountValue;
        document.getElementById("costBy").value = selectedPromotion["cost_by"];

        //Validate discount amount does not exceed admin fee
        if (this.discountAmountValue > this.adminFeeValue) {
            this.discountAmountValue = this.adminFeeValue;
            this.totalFeeValue = 0;
        } else {
            this.totalFeeValue = this.adminFeeValue - this.discountAmountValue;
        }

        this.totalFeeTarget.textContent = `RM ${this.totalFeeValue}`
        this.discountAmountTarget.textContent = `RM ${this.discountAmountValue}`
    }
    initialize(){
        this.isShowOthersTextField()
        this.isSelectOthers()
        this.adminFeeTarget.textContent = `RM ${this.adminFeeValue}`
        this.totalFeeValue = this.adminFeeValue;
        const controller = this;

        controller.handlePromotion()

        this.salaryValue = parseInt(document.getElementById("amount").value)
        this.totalAmountTarget.textContent = `RM ${this.salaryValue - this.totalFeeValue}`
    }
}