import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

    //Initialize employee spreadsheet data for confirmation
    const dataHolder = document.getElementById('spreadsheet-data-holder');
    const spreadsheetData = dataHolder.getAttribute('data-spreadsheet');
    const employees = JSON.parse(spreadsheetData)
    employees.forEach( item => {
        item['11'] = 'invited';
    })

    //update employee spreadsheet data to send invites
    document.querySelectorAll('.employee-checkbox').forEach(checkbox => {
        checkbox.addEventListener('change', () => {
          employees[checkbox.dataset.index]['11'] = checkbox.checked ? 'invited' : 'registered';
        });
    });

    const form = document.querySelector('#form-id');
    form.addEventListener('submit', () => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'spreadsheet_data';
        input.value = JSON.stringify(employees);
        form.appendChild(input);
    });

  }
 
}



