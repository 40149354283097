import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["url","amount","address"]

    getVal(){
        return this.amountTarget.value
    }

    getRequestAdvanceUrl(){
        const isAddressExists = this.addressTarget.value
        const url = isAddressExists == "true" ? "request_advance/confirm_request" : "request_advance/add_address"
        this.urlTarget.href = `${url}?amount=${this.getVal()}`
    }

    resizeInput(){
        document.getElementById("rangeValue").value = this.amountTarget.value
        this.amountTarget.style.width = this.amountTarget.value.length + "ch";
    }

    changeValue(){
        const cappedAmount = Math.round(document.getElementById("cappedAmount").value)
        if(Math.round(this.amountTarget.value) < 60) this.amountTarget.value = 60
        if(Math.round(this.amountTarget.value) > 500) {
            this.amountTarget.value = 500
            this.resizeInput();
        }
        this.getRequestAdvanceUrl()
        if(Math.round(this.amountTarget.value) > Math.round(cappedAmount)) {
            this.urlTarget.href = '?invalid=true'
        }
    }

    rangeValue(){
        this.amountTarget.value = document.getElementById("rangeValue").value
        this.amountTarget.style.width = this.amountTarget.value.length + "ch";
        this.getRequestAdvanceUrl()
    }

    initialize() {
        this.getRequestAdvanceUrl()
    }
}