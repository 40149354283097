import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    constructor() {
        super();
        this.debounceTimer = null; 

        this.employeeData = []; //all active/frozen employees
        this.filteredEmployeeData = []; //to display search results from employeeData

        this.selectedEmployees = []; //selected employees for display purposes
        this.selectedEmployeeIds = []; //selected employees id for form submission
    }

    connect() {
        //Initiailization
        this.audienceTypeListener();
        this.fetchSelectedEmployees();
        
        //To show selection UI
        const searchButton = document.getElementById("search-button");
        searchButton.addEventListener("click", () => {
            var searchList = document.getElementById("search-list");
            searchList.classList.remove("hidden");
            this.fetchEmployees();
        });

        //Attach input listener to search bar
        const employeeSearch = document.getElementById("employee-search");
        employeeSearch.addEventListener("input", () => {
            // Clear the previous debounce timer
            clearTimeout(this.debounceTimer);

            // Start a new debounce timer to delay the execution of the handler
            this.debounceTimer = setTimeout(() => {
                this.employeeSearchHandler(employeeSearch.value.toLowerCase());
            }, 300); // Set the debounce delay (e.g., 300ms)
            
        });
    }

    fetchEmployees() {
        const url = '/employees/search'

        Rails.ajax({
            type: 'GET',
            url: url,
            success: (data) => {
                this.employeeData = data;
            },
            error: (error) => {
                console.error(error)
            }
        })
    }

    fetchSelectedEmployees() {
        const url = '/employees/search'

        const promotionId = document.getElementById("promotion-data").getAttribute("data-promotion-id");
        if (promotionId === '') return;
        const data = {
            id: promotionId,
            type: 'promotion'
        };

        Rails.ajax({
            type: 'POST',
            url: url,
            data: JSON.stringify(data),
            success: (data) => {
                this.selectedEmployees = data;
                this.initializeSelectedEmployees();
            },
            error: (error) => {
                console.error(error)
            }
        })
    }

    employeeSearchHandler(query) {
        const employeeList = document.getElementById("employee-list");
        this.filteredEmployeeData = this.employeeData.filter(employee => {
            return (
                employee.fullname.toLowerCase().includes(query) ||
                employee.mobile_number.toLowerCase().includes(query) ||
                employee.department.toLowerCase().includes(query)
            );
        });
        // Display employee list when the user starts typing in the search bar
        employeeList.innerHTML = "";
        this.filteredEmployeeData.forEach(employee => {
            var listItem = document.createElement("div");
            listItem.classList.add("p-1");
            listItem.classList.add("flex");
            listItem.classList.add("items-center");

            listItem.innerHTML =
                '<div class="btn btn-secondary p-1 h-4 mr-2" data-action="click->promotion#addEmployee" data-employee-id="' + employee.id + '"><i class="fa-solid fa-plus"></i></div>' +
                '<div name="employee_id"> ' +
            employee.fullname + ' | ' + employee.mobile_number + ' | ' + employee.department;
            employeeList.appendChild(listItem);
        });
    }

    initializeSelectedEmployees() {
        this.selectedEmployeeIds = []
        
        const employeeList = document.getElementById("selected-employee-list");
        
        // Display employee list when the user starts typing in the search bar
        employeeList.innerHTML = "";
        this.selectedEmployees.forEach(employee => {
            this.selectedEmployeeIds.push(employee.id)
            var listItem = document.createElement("div");
            listItem.classList.add("p-1");
            listItem.classList.add("flex");
            listItem.classList.add("items-center");

            listItem.innerHTML =
                '<div class="btn btn-danger p-1 h-4 mr-2" data-action="click->promotion#removeEmployee" data-employee-id="' + employee.id + '"><i class="fa-solid fa-x"></i></div>' +
                '<div name="selected_employee_id">' +
                employee.fullname + ' | ' + employee.mobile_number + ' | ' + employee.department;
                employeeList.appendChild(listItem);
        });

        const hiddenField = document.getElementById("promo_employee_ids");
        hiddenField.value = JSON.stringify(this.selectedEmployeeIds);
    }

    audienceTypeListener() {
        const audienceTypeSelect = document.getElementById("promotion_audience_type");
        const companySelectGroup = document.getElementById("company-select-group");
        const employeeSelectGroup = document.getElementById("employee-select-group");

        audienceTypeSelect.addEventListener("change", function () {
            if (this.value === "company") {
                companySelectGroup.style.display = "block";
                employeeSelectGroup.style.display = "none";
            } else if (this.value === "employee") {
                companySelectGroup.style.display = "none";
                employeeSelectGroup.style.display = "block";
            } else {
                companySelectGroup.style.display = "none";
                employeeSelectGroup.style.display = "none";
            }
        });

        // Initial check on page load
        if (audienceTypeSelect.value === "company") {
            companySelectGroup.style.display = "block";
            employeeSelectGroup.style.display = "none";
        } else if (audienceTypeSelect.value === "employee") {
            companySelectGroup.style.display = "none";
            employeeSelectGroup.style.display = "block";
        } else {
            companySelectGroup.style.display = "none";
            employeeSelectGroup.style.display = "none";
        }
    }

    addEmployee(event) {
        const employeeIdToAdd = event.currentTarget.getAttribute('data-employee-id');
        const employeeToAdd = this.employeeData.find((employee) => employee.id == employeeIdToAdd);
        const employeeExist = this.selectedEmployees.find((employee) => employee.id == employeeIdToAdd);

        if (employeeToAdd && !employeeExist) {
          this.selectedEmployeeIds.push(employeeIdToAdd);
          this.selectedEmployees.push(employeeToAdd);
          
          // Add the employee entry to the employeeList UI
          const employeeList = document.getElementById("selected-employee-list");
          var listItem = document.createElement("div");
          listItem.classList.add("p-1");
          listItem.classList.add("flex");
          listItem.classList.add("items-center");
          listItem.innerHTML =
            '<div class="btn btn-danger p-1 h-4 mr-2" data-action="click->promotion#removeEmployee" data-employee-id="' + employeeToAdd.id + '"><i class="fa-solid fa-x"></i></div>' +
            '<div name="selected_employee_id">' +
            employeeToAdd.fullname + ' | ' + employeeToAdd.mobile_number + ' | ' + employeeToAdd.department;
          employeeList.prepend(listItem);
          
          // Update the hidden field's value
          const hiddenField = document.getElementById("promo_employee_ids");
          hiddenField.value = JSON.stringify(this.selectedEmployeeIds);
        }
    }

    removeEmployee(event) {
        const employeeIdToRemove = event.currentTarget.getAttribute('data-employee-id');
        this.selectedEmployeeIds = this.selectedEmployeeIds.filter(id => id != employeeIdToRemove);
        this.selectedEmployees = this.selectedEmployees.filter(employee => employee.id != employeeIdToRemove);
        
        // Also remove the corresponding employee entry from the employeeList UI
        const listItem = event.currentTarget.parentNode;
        if (listItem) {
          listItem.remove();
        }
        
        // Update the hidden field's value
        const hiddenField = document.getElementById("promo_employee_ids");
        hiddenField.value = JSON.stringify(this.selectedEmployeeIds);
    }

    cancel() {
        var searchList = document.getElementById("search-list");
        searchList.classList.add('hidden');
    }

    save() {
        const hiddenField = document.getElementById("promo_employee_ids");
        hiddenField.value = JSON.stringify(this.selectedEmployeeIds);
    }
}
