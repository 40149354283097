import { Controller } from '@hotwired/stimulus';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
    static targets = [ "input" ]
    static values = { hiddenfield: String }

    connect() {
        const input = this.inputTarget;
        this.iti = intlTelInput(input, {
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js",
            initialCountry: "my",
            preferredCountries: ["my", "sg", "id"],
            // separateDialCode: true,
            autoPlaceholder: "aggressive",
            formatOnDisplay: false
        });
        input.addEventListener('input', () => {
            this.change();
        });
    }

    change() {
        const userMobileNumberInput = document.querySelector('#user_mobile_number');
        const mobileNumberInput = document.querySelector('#mobile_number');

        if (userMobileNumberInput) {
            userMobileNumberInput.value = this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
        }

        if (mobileNumberInput) {
            mobileNumberInput.value = this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
        }
    }
  }
