import { Controller } from "@hotwired/stimulus"

let updatedEmployees = [];

export default class extends Controller {

  connect() {
    //intialize updated employees with all as 'Send'
    document.querySelectorAll('.employee-select').forEach(select => {
      const action = select.options[select.selectedIndex].value;
      const employeeId = select.dataset.id;
      const updatedEmployee = { id: employeeId, action: action };
      updatedEmployees.push(updatedEmployee);
    });

    console.log("Initialized employees invitation list: ", updatedEmployees);

    document.querySelectorAll('.employee-select').forEach(select => {
        select.addEventListener('change', () => {
            const action = select.options[select.selectedIndex].value;
            const employeeId = select.dataset.id;
            const updatedEmployee = { id: employeeId, action: action };
            
            const employeeExists = updatedEmployees.find(employee => employee.id === employeeId);
            
            if (employeeExists) {
                employeeExists.action = action;
            } else {
                updatedEmployees.push(updatedEmployee);
            }
        });
      });

    const form = document.querySelector('#form-id');
    form.addEventListener('submit', () => {
        console.log("Sending invitations to: ", updatedEmployees);
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'updated_employees';
        input.value = JSON.stringify(updatedEmployees);
        form.appendChild(input);
    });

  }
 
}



